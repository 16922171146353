<template>
    <div>
        <v-flex xs12 sm8 offset-sm-2 class="my-4 px-8">
            <v-card class="white--text mb-1 mt-8 " flat :tile="true">
                <v-btn
                        fab
                        small
                        color="primary accent-2"
                        bottom
                        right
                        absolute
                        @click="goHome"
                >
                    <v-icon>mdi-home</v-icon>
                </v-btn>

                <v-container fluid grid-list-lg>
                    <v-layout row>
                        <v-flex xs7>
                            <div>
                                <div class="display-1 black--text text--darken-1">Privacy Policy</div>
                                <!--                <div class="grey&#45;&#45;text">-->
                                <!--                    Your one stop shop for all poultry products-->
                                <!--                </div>-->
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card>

            <v-card color="white mb-15" flat class="white--text" height="100%">

                <!--comes here-->
                <v-card flat>
                    <v-card-text>
                        <!--//Tab contents-->
                        <v-container fluid grid-list-lg class="">
                            <v-layout row>
                                <v-flex xs12>
                                    <div class="">
                                        <div class="headline primary--text"></div>
                                        <blockquote class="grey--text text--darken-1">
                                            This Privacy Policy governs the manner in which Agro Innova collects, uses, maintains, 
                                            and discloses information collected from users (referred to as "User(s)" or "you") of the 
                                            akokomarket.com website ("Website"). This policy applies to the Website and all products 
                                            and services offered by Agro Innova.


                                            <v-divider class="my-4"></v-divider>

                                            <div class="headline primary--text">Introduction</div>

                                            At Agro Innova, we are committed to protecting your privacy and ensuring the security
                                             of your personal information. This Privacy Policy outlines the types of information
                                              we collect, how we use and protect it, and your rights regarding the information we hold. 
                                              By using our Website, you consent to the collection and use of your information as described in this policy.

                                            <v-divider class="my-4"></v-divider>

                                            <div class="headline primary--text">Information Collection</div>

                                            <strong>2.1 Personal Information</strong>
                                            <br/>

                                            We may collect personal information from Users in various ways, including but not 
                                            limited to when Users visit our Website, register on the Website, place an order, 
                                            subscribe to the newsletter, fill out a form, or interact with other activities, services, 
                                            features, or resources we make available on our Website. Personal information may include, 
                                            but is not limited to, your name, email address, postal address, phone number, and payment details.

                                            <br/>
                                            <br/>

                                            <strong>2.2 Non-personal Information</strong>
                                            <br/>

                                            We may collect non-personal information about Users whenever they interact with our Website. 
                                            Non-personal information may include the browser name, the type of computer or device, and 
                                            technical information about Users' means of connection to our Website, such as the operating system, 
                                            internet service provider utilized, and other similar information.
                                            <br/>
                                            <br/>

                                            <i>Information Use</i>
                                            <br/>
                                            We may collect and use Users' personal information for the following purposes:
                                            <br/>

                                            ~ To personalize user experience: We may use the information to understand how our 
                                            Users as a group use the services and resources provided on our Website 
                                            and to improve our products and services.
                                            <br/>
                                            ~ To process payments: We may use the information Users provide when placing an 
                                            order to process that order. We do not share this information with outside 
                                            parties except to the extent necessary to provide the service.
                                            <br/>
                                            ~ To send periodic emails: We may use the email address provided by Users to send
                                            them information and updates pertaining to their order. It may also be used to 
                                            respond to their inquiries, questions, and/or other requests. If the User decides 
                                            to opt-in to our mailing list, they will receive emails that may include company news, 
                                            updates, related product or service information, etc. If at any time the User would 
                                            like to unsubscribe from receiving future emails, we include detailed unsubscribe 
                                            instructions at the bottom of each email.
                                            <br/>
                                            <br/>

                                            <i>Information Protection</i>
                                            <br/>
                                            We adopt appropriate data collection, storage, and processing practices and security
                                            measures to protect against unauthorized access, alteration, disclosure, or destruction
                                            of your personal information, username, password, transaction information, and data 
                                            stored on our Website.
                                            <br/>
                                            <br/>

                                            <i>Sharing Personal Information</i>
                                            <br/>
                                            We do not sell, trade, or rent Users' personal identification information to others. 
                                            We may share generic aggregated demographic information not linked to any personal 
                                            identification information regarding visitors and Users with our business partners, 
                                            trusted affiliates, and advertisers for the purposes outlined above.
                                            <br/>
                                            <br/>

                                            <i>Third-Party Websites</i>
                                            <br/>
                                            Users may find advertising or other content on our Website that links to the sites 
                                            and services of our partners, suppliers, advertisers, sponsors, licensors, and other 
                                            third parties. We do not control the content or links that appear on these sites and are 
                                            not responsible for the practices employed by websites linked to or from our Website. 
                                            Browsing and interaction on any other website, including websites which have a link to our Website, 
                                            is subject to that website's own terms and policies.
                                            <br/>
                                            <br/>

                                            <i>Compliance with Laws</i>
                                            <br/>
                                            We will disclose your personal information where required to do so by law or subpoena or 
                                            if we believe that such action is necessary to comply with the law and the reasonable 
                                            requests of law enforcement or to
                                        </blockquote>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-card>
        </v-flex>
    </div>
</template>

<script>
    export default {
        name: "privacy_policy",

        data: () => ({
            text: "",
        }),

        methods: {
            goHome() {
                this.$router.push("/");
            },
            addItem(item) {
                const removed = this.items.splice(0, 1);
                this.items.push(...this.more.splice(this.more.indexOf(item), 1));
                this.more.push(...removed);
                this.$nextTick(() => {
                    this.currentItem = "tab-" + item;
                });
                alert(this.currentItem);
            }
        },
        created() {},
        components: {}
    };
</script>

<style scoped></style>
